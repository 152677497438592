body {
}

.App {
  background-color: #23252C;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.card {
  border-radius: 20px;
  overflow: hidden;
  background-color: #1A1B21;
  color: white;
  margin: 20px;
  max-width: 80%;
  width: 350px;
}

.card img {
  width: 100%;
}

.info--details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info--details h2 {
  margin-bottom: 0px;
}

.info--position {
  color: #F3BF99;
  margin-top: 5px;
}

.info--details a {
  text-decoration: none;
  color: inherit;
  margin-top: 5px;
  font-size: small;
}

.info--buttons {
  margin: 20px 35px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.info--buttons a {
  flex: 1 1 0px;
  border-radius: 6px;
  text-decoration: none;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.info--buttons img {
  height: 20px;
  width: 20px;
}

.button--email {
  background-color: white;
  color: #374151;
}

.button--resume {
  background-color: white;
  color: #374151;
}

.button--linkedin {
  background-color: #5093E2;
  color: white;
}

.about {
  margin: 35px 35px 0px;
}

.about h3, .about p {
  margin: 0;
}

.about p {
  margin-top: 5px;
  font-size: small;
  color: #DCDCDC;
}

.about .strong {
  font-weight: bold;
}

.footer {
  background-color: #161619;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  height: 70px;
  margin-top: 35px;
}

.location {
  margin-bottom: -10px;
}
.location img {
  height: 1rem;
  display: inline;
  width: auto;
  vertical-align: bottom;
}

